import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import Pay from './pay';
import PaySuccess from './pay-success';
import PayError from './pay-error';

function App() {
    return (
      <div className="App">
        <BrowserRouter>
            <Route exact={true} path="/" component={Pay} />
            <Route exact={true} path="/pay-success" component={PaySuccess} />
            <Route exact={true} path="/pay-error" component={PayError} />
        </BrowserRouter>
      </div>
    );
}

export default App;