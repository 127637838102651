import React from 'react';
import CheckoutForm from "./checkoutForm";
import Logo from './logo.png';
import Lock from './lock-solid.svg';
import LH from './leadhouse.png';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const promise = loadStripe("pk_live_51HRdWRCpZPm0815LuA3Zkj9LBaM1QF7zbx6gud71d14SRCWAEaSDXd71WUFM4sog9rqbKJUDJQmAffcpre7k4cYk00AlBXOSCg")

function Pay() {
    return (
        <div className="pay__main">
            <div className="pay__container">
                <div className="pay__header">
                    <img src={Logo} alt="Logo" className="pay__logo"/>
                </div>

                <div className="pay__information">
                    <Elements stripe={promise}>
                        <CheckoutForm />
                    </Elements>
                </div>

                <div className="pay__footer">
                    <img src={Lock} alt="lock" /> Secure payments powered by <a href="https://stripe.com/en-ca" target="_blank" rel="noopener noreferrer">Stripe</a>
                </div>
            </div>
            <img src={LH} alt="leadhouse logo" className="pay__watermark" />
        </div>
    );
}

export default Pay;