import React from 'react';
import Logo from "./logo.png";
import Success from './success.svg';

function PaySuccess() {
    return (
        <div className="pay__main">
            <div className="pay__container">
                <div className="pay__header">
                    <img src={Logo} alt="leadhouse_logo" className="pay__logo"/>
                </div>
                <div className="pay__information">
                    <div className="pay__status">
                        <div className="pay__status__icon pay__success">
                            <img src={Success} alt="success" />
                        </div>
                        <h5 className="pay__status">Payment succesful</h5>
                    </div>
                    <div className="pay__status__text">
                        You should see your payment applied to your invoice shortly
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaySuccess;