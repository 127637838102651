import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from 'axios';

function CheckoutForm() {
    let history = useHistory();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [reference, setReference] = useState('');
    const [amount, setAmount] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);

        axios.post('https://backend.pay.revelvillas.com/api/v1/payment', {
            amount: amount,
            token: result.token.id,

            name: name,
            phone: phone,
            email: email,
            reference: reference
        }).then((res => {
            history.push('/pay-success')
        })).catch((err => {
            history.push('/pay-error')
        }))
    }

    return (
        <div className="checkout">
            <form id="pay__form" onSubmit={handleSubmit}>
                <div className="pay__field">
                    <input 
                        className="pay__input" 
                        type="text" 
                        placeholder="Enter your name" 
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                    /><label>Name</label>
                </div>
                <div className="pay__field">
                    <input 
                        className="pay__input" 
                        type="text" 
                        placeholder="Enter your phone" 
                        name="phone" 
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    /><label>Phone</label>
                </div>
                <div className="pay__field">
                    <input 
                        className="pay__input" 
                        type="email" 
                        placeholder="Enter your email" 
                        name="email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    /><label>Email</label>
                </div>
                <div className="pay__field">
                    <input 
                        className="pay__input" 
                        type="text"
                        maxLength="20"
                        placeholder="Enter reference number" 
                        name="reference"
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                    /><label>Reference (#)</label>
                </div>
                <div className="pay__field">
                    <input 
                        className="pay__input"
                        type="number"
                        placeholder="Enter Payment Amount"
                        name="amount"
                        step="0.01"
                        data-number-to-fixed="2"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    /><label>Amount ($)</label>
                </div>
            </form>
            <CardElement />
            <div className="pay__submit">
                <button className="pay__button" type="submit" form="pay__form">PAY</button>
            </div>
        </div>
    );
}

export default CheckoutForm;