import React from 'react';
import Logo from "./logo.png";
import Error from './error.svg';

function PayError() {
    return (
        <div className="pay__main">
            <div className="pay__container">
                <div className="pay__header">
                    <img src={Logo} alt="leadhouse logo" className="pay__logo"/>
                </div>
                <div className="pay__information">
                    <div className="pay__status">
                        <div className="pay__status__icon pay__error">
                            <img src={Error} alt="error" />
                        </div>
                        <h5 className="pay__status">Payment Error</h5> <br/>
                        <a href="/" className="pay__retry">Retry</a>
                    </div>
                    <div className="pay__status__text">
                        Your payment was unsuccessful, please try again later. If the issue persists, please contact support 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PayError;